import { type JSX, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { type Price } from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickElement,
    GA4EventName,
    GA4FeatureCategory,
    type GA4ItemListName,
    type Product as ProductTrackingType,
    SearchType,
    trackClickTextLink,
    trackEecProductClick,
    trackEecProductImpression,
    trackSubmitSearch,
} from '@jsmdg/tracking';
import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    ChevronIcon,
    Link,
    LinkVariant,
    RenderType,
    SimplifiedProductTile,
} from '@jsmdg/yoshi';
import { type Product } from '../../../shared/types';
import { TrackingListName } from '../../enums/TrackingListName';
import { useLocationLabel } from '../../hooks/useLocationLabel';
import { type HeaderContext } from '../../types';
import { LoadingWrapper } from '../LoadingWrapper/LoadingWrapper';
import { SkeletonTile } from './SkeletonTile';
import commonStyles from '../common/common_styles.module.scss';
import styles from './SearchFlyout.module.scss';

const messages = defineMessages({
    showMoreExperiences: {
        defaultMessage: 'Mehr Erlebnisse entdecken',
    },
});

const trackingProductTypeMap = (product: Product): ProductTrackingType => ({
    id: product.productId,
    price: {
        gross: {
            amount: Number(product.price.gross.amount),
            currencyCode: product.price.gross.currencyCode,
        },
    },
    manufacturerSku: product.manufacturerSku,
    productType: product.productType,
    trackingPath: product.trackingPath || [],
    title: product.title,
    trackingName: product.trackingName,
    url: product.url,
    originalPrice: product.originalPrice && {
        gross: {
            amount: Number(product.originalPrice.gross.amount),
            currencyCode: product.originalPrice.gross.currencyCode,
        },
    },
    locationCount: product.locations?.length,
});

type SimplifiedProductTileWrapperProps = {
    readonly products: Product[];
    readonly title: string;
    readonly showButton?: boolean;
    readonly isWideView?: boolean;
    readonly className?: string;
    readonly loading: boolean;
    readonly skeletonSize: number;
    readonly onItemClick: (
        title: string,
        link: string,
        position: number,
        productId: string,
        indexName?: string,
        queryId?: string,
    ) => void;
    readonly addToHistory: (title: string, link?: string) => void;
    readonly testId?: string;
    readonly searchTerm?: string;
    readonly indexName?: string;
    readonly queryId?: string;
    readonly searchType: SearchType;
};

const SimplifiedProductTileWrapper = ({
    addToHistory,
    className = '',
    indexName,
    isWideView = false,
    loading,
    onItemClick,
    products,
    queryId,
    searchTerm,
    searchType,
    showButton = false,
    skeletonSize,
    testId,
    title,
}: SimplifiedProductTileWrapperProps): JSX.Element => {
    const { featureFlagsVariation, isMydays, tenantConfig } = useFragmentContext<HeaderContext>();
    const intl = useIntl();
    const getLocationLabel = useLocationLabel();

    const renderPrice = (price: Price | undefined): string | undefined => {
        if (!price) {
            return undefined;
        }

        return intl.formatNumber(+price.gross.amount, {
            style: 'currency',
            currency: price.gross.currencyCode,
        });
    };

    const defaultStyles = 'py-md-3x pt-3x pb-3x pl-md-3x';
    const showMoreLink = searchTerm ? `/search?q=${searchTerm}` : `/alle-erlebnisse/l/79ifa`;

    const skeletons = Array.from({ length: skeletonSize }, (_, index) => (
        <SkeletonTile
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            testId={`${testId ? `${testId}-skeleton` : ''}`}
        />
    ));

    const eecList = useRef<TrackingListName | undefined>(undefined);

    useEffect(() => {
        const updateTrackingEecList = (): void => {
            switch (searchType) {
                case SearchType.LastSeenExperiences:
                    eecList.current = TrackingListName.SearchLastSeen;
                    break;
                case SearchType.PopularExperiences:
                    eecList.current = TrackingListName.SearchBestseller;
                    break;
                case SearchType.MatchingSearchExperience:
                    eecList.current = TrackingListName.SearchSuggestSearchProduct;
                    break;
                default:
                    throw new Error(`Unhandled searchType: ${searchType}`);
            }
        };

        updateTrackingEecList();

        products.forEach((product: Product, index: number) => {
            trackEecProductImpression({
                product: trackingProductTypeMap(product),
                position: index + 1,
                list: eecList.current,
                featureCategory: GA4FeatureCategory.Search,
                itemListName: eecList.current as unknown as GA4ItemListName,
                eventName: GA4EventName.ViewItemList,
            });
        });
    }, [products, searchType]);

    const handleOnClick = (e: React.MouseEvent, product: Product, index: number): void => {
        if (process.env.NODE_ENV !== 'test' && !e.isTrusted) return;

        onItemClick(product.title, product.url, index + 1, product.productId, indexName, queryId);

        trackSubmitSearch(
            product.title,
            searchType,
            searchTerm || '',
            product.url,
            products.length,
        );

        trackEecProductClick({
            product: trackingProductTypeMap(product),
            position: index + 1,
            list: eecList.current,
            featureCategory: GA4FeatureCategory.Search,
            itemListName: eecList.current as unknown as GA4ItemListName,
        });
    };

    const getPriceClassName = (originalPrice?: Price | string): string | undefined => {
        if (
            !originalPrice ||
            isMydays ||
            !featureFlagsVariation?.strike_price_main ||
            featureFlagsVariation?.strike_price_main === 'default'
        )
            return undefined;

        return featureFlagsVariation?.strike_price_main === 'red'
            ? commonStyles.redStrikePrice
            : commonStyles.blueStrikePrice;
    };

    return (
        <div
            className={classNames(
                'd-flex flex-column pl-3x pl-xs-4x',
                styles.experienceWrapper,
                className || defaultStyles,
                {
                    'flex-1': isWideView,
                },
            )}
            data-testid={testId}
        >
            <div className={classNames('fw-bold mb-3x', styles.searchFlyoutTitle)}>{title}</div>
            <div className="d-flex flex-column flex-xs-row flex-md-column">
                <LoadingWrapper loading={loading} loadingComponent={skeletons}>
                    {products.map((product: Product, index: number) => (
                        <SimplifiedProductTile
                            key={product.title}
                            className="mb-1-5x"
                            lazyLoad
                            name={product.title}
                            price={renderPrice(product.price)}
                            originalPrice={renderPrice(product.originalPrice)}
                            image={product.images[0]}
                            url={product.url}
                            locationLabel={getLocationLabel(product)}
                            isWideView={isWideView}
                            onClick={e => handleOnClick(e, product, index)}
                            rating={product.rating?.value}
                            priceClassName={getPriceClassName(product.originalPrice)}
                        />
                    ))}
                </LoadingWrapper>
            </div>
            {!showButton && (
                <Link
                    variant={LinkVariant.Brand}
                    href={tenantConfig.allExperiencesPath}
                    className="d-flex mt-3x align-items-center"
                    onClick={(e: { isTrusted: boolean }) => {
                        if (process.env.NODE_ENV !== 'test' && !e.isTrusted) return;
                        if (searchType === SearchType.PopularExperiences) {
                            trackClickTextLink(
                                tenantConfig.allExperiencesLabel,
                                tenantConfig.allExperiencesPath,
                                ClickElement.SearchMorePopularExperiences,
                            );
                        } else if (searchType === SearchType.LastSeenExperiences) {
                            trackClickTextLink(
                                tenantConfig.allExperiencesLabel,
                                tenantConfig.allExperiencesPath,
                                ClickElement.SearchMoreLastSeenExperiences,
                            );
                        }
                    }}
                >
                    {tenantConfig.allExperiencesLabel}
                    <ChevronIcon
                        size={21}
                        className={classNames(styles.showMore, 'align-text-bottom')}
                    />
                </Link>
            )}
            {showButton && (
                <Button
                    as={RenderType.Link}
                    href={showMoreLink}
                    color={ButtonColor.Brand}
                    variant={ButtonVariant.Outlined}
                    className="d-flex mt-2x align-items-center align-self-sm-start align-self-md-end"
                    size={ButtonSize.Small}
                    onClick={(e: { isTrusted: boolean }) => {
                        if (process.env.NODE_ENV !== 'test' && !e.isTrusted) return;
                        addToHistory(searchTerm || '');
                        trackClickTextLink(
                            intl.formatMessage(messages.showMoreExperiences),
                            showMoreLink,
                            ClickElement.SearchMoreMatchingExperiences,
                        );
                    }}
                >
                    {intl.formatMessage(messages.showMoreExperiences)}
                </Button>
            )}
        </div>
    );
};

export { SimplifiedProductTileWrapper };
